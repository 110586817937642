<template>
    <div>
        <div class="testimonial-cycler-box">
            <div class="testimonial-cycler-box__bg" style="background-image: url(/assets/images/brand/logo-light-swish.svg);"></div>

            <h4 class="line">Testimonials</h4>

            <div class="testimonial-cycler-box__items" :class="{'transitioning': transitioning}" :style="{'transition': `all ${halfTransitionTime}ms`}">
                <div class="testimonial-cycler-box__items__viewport" :style="{transform: 'translateX(-' + (index * 100) + '%)'}">
                    <div class="testimonial-cycler-box__item" v-for="(testimonial, idx) in testimonials" :key="idx" :class="{active: idx == index}">
                        <RichText :content="testimonial.Description"></RichText>

                        <h5 class="testimonial__name">{{testimonial.ClientName}}</h5>
                        <h5 v-if="testimonial.Suburb" class="testimonial_address">{{testimonial.Address ? testimonial.Address + ', ' : ''}}{{testimonial.Suburb}}</h5>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import RichText from '../Common/RichText.vue';

export default {
  components: { RichText },
  props: {
    leftPattern: String,
    rightImage: String,
    testimonials: Array,
    transitionTime: {
      type: Number,
      default: 500,
    },
    cycleTime: {
      type: Number,
      default: 10000,
    },
  },
  data() {
    return {
      transitioning: false,
      index: 0,
    };
  },
  computed: {
    halfTransitionTime() {
      return this.transitionTime / 2;
    },
  },
  mounted() {
    setInterval(() => {
      this.next();
    }, this.cycleTime);
  },
  methods: {
    doTransition(callback) {
      this.transitioning = true;

      setTimeout(() => {
        callback();

        setTimeout(() => {
          this.transitioning = false;
        }, this.halfTransitionTime);
      }, this.halfTransitionTime);
    },
    next() {
      this.doTransition(() => {
        this.index += 1;
        if (this.index === this.testimonials.length) this.index = 0;
      });
    },
    prev() {
      this.doTransition(() => {
        this.index -= 1;
        if (this.index === -1) this.testimonials.length -= 1;
      });
    },
  },
};
</script>

<style scoped>
    .testimonial-cycler-box__items.transitioning {
        opacity: 0;
    }

    .testimonial-cycler-box__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    .testimonial-cycler-box {
        display: block;
        position: relative;
        color: #fff;
        border-radius: 4px;
        min-height: 25vh;
        overflow: hidden;
    }

        .testimonial-cycler-box > .testimonial-cycler-box__bg {
            opacity: 0.025;
            z-index: 0;
        }

        .testimonial-cycler-box > h4.line {
            font-size: 1.2em;
            display: flex;
            align-items: center;
            margin-bottom: 1em;
        }

            .testimonial-cycler-box > h4.line:before {
                content: " ";
                display: block;
                width: 3em;
                height: 1px;
                background: #fff;
                margin-right: 1em;
            }

    h5.testimonial__name,
    h5.testimonial_address {
        font-weight: bold;
        margin: 1em 0;
    }

    .testimonial-cycler-box__items__viewport {
        display: flex;
        align-items: flex-start;
    }

    .testimonial-cycler-box__item {
        flex-shrink: 0;
        display: block;
        width: 100%;
        opacity: 0;
        pointer-events: none;
    }

        .testimonial-cycler-box__item.active {
            opacity: 1;
            pointer-events: initial;
        }

    @media screen and (max-width: 980px) {
        .testimonial-cycler-box {
            flex-direction: column;
        }

            .testimonial-cycler-box > div {
                width: 100%;
            }

            .testimonial-cycler-box > .testimonial-cycler-box__right {
                display: none;
            }

            .testimonial-cycler-box__left {
                min-height: 75vh;
            }
    }
</style>
