
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { ResizableImage } from 'client-website-ts-library/types';

@Component
export default class LazyImage extends Vue {
  @Prop()
  private readonly image!: ResizableImage | string;

  @Prop()
  private readonly defer!: boolean;

  private deferrable = false;

  private loaded = false;

  private source: string | null = null;

  private delay = 100;

  private visibilityCheckTimeout = 0;

  private isVisible = false;

  private hasErrored = false;

  private eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

  onInView(): void {
    this.loadHighRes();
  }

  loadHighRes(): void {
    if (this.loaded) return;

    this.loaded = true;

    if (typeof this.image === 'string') {
      requestAnimationFrame(() => {
        this.source = this.image as string;
      });

      return;
    }

    if (this.image.Preview.Url !== null && this.image.Preview.Url.length !== 0) {
      requestAnimationFrame(() => {
        this.source = (this.image as ResizableImage).Preview.Url;
      });
    }
  }

  update(): void {
    const transparent = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

    this.loaded = false;

    if (this.defer || typeof this.image === 'string') {
      // Use the transparent gif
      this.source = transparent;
      this.deferrable = true;
      this.delay = 100;
    } else {
      this.source = (this.image as ResizableImage).Thumbs.Url;
      this.delay = 500;
    }

    this.setupListener();
  }

  destroyListener(): void {
    document.removeEventListener('scroll', this.checkPos, this.eventListenerOpts);
  }

  setupListener(): void {
    document.addEventListener('scroll', this.checkPos, this.eventListenerOpts);

    requestAnimationFrame(() => {
      this.checkPos();
    });
  }

  checkPos(): void {
    clearTimeout(this.visibilityCheckTimeout);

    this.visibilityCheckTimeout = setTimeout(() => {
      const el = this.$el;

      const rect = el.getBoundingClientRect();

      this.isVisible = rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight);

      if (this.isVisible) {
        this.destroyListener();
        this.onInView();
      }
    }, this.delay);
  }

  @Watch('image')
  handleImageChanged(): void {
    this.update();
  }

  mounted() {
    this.update();
  }

  beforeDestroy() {
    this.destroyListener();
  }

  handleError(): void {
    if (this.hasErrored) return;

    this.hasErrored = true;

    if (this.loaded && typeof this.image !== 'string') {
      this.source = (this.image as ResizableImage).Thumbs.Url;
    }
  }
}
