import { ConfigRoot } from 'client-website-ts-library/services';

const config = {
  Mode: 'live',
  Website: {
    Id: '11F8CF52-56DA-44F4-B8B7-BDE9218A0A3E',
    Name: 'Barrier Reef Business Brokers',
  },
  API: {
    Base: 'https://clientapi.prolist.net.au',
    Base1: 'http://localhost:50283',
    WebsiteId: '05fde9a0-a00c-44ad-9283-17976a355b2c',
    WebsiteLevel: 3,
  },
  RUM: {
    Skywalker: {
      Base: 'https://skywalker.prolist.net.au',
    },
  },
  Services: {
    Firebase: {
      VAPIDKey: 'BFgYEBsC_jqH9Fw5ThYhp7eU9lzRXEiVr58ugie9aqb4bw-A4qgbAq8KjXrqzLR9OwNrm-yoE5zaAqmUuwwaknU',
      Config: {
        apiKey: 'AIzaSyDtzgq_WF95GdYg3g6WHO8x-Kcl9lnrmMk',
        authDomain: 'prolist-client-websites.firebaseapp.com',
        databaseURL: 'https://prolist-client-websites.firebaseio.com',
        projectId: 'prolist-client-websites',
        storageBucket: 'prolist-client-websites.appspot.com',
        messagingSenderId: '803803379511',
        appId: '1:803803379511:web:142acacdeb724ab0a00cb2',
        measurementId: 'G-SY8NCZNXD1',
      },
    },
  },
} as ConfigRoot;

export default config;
